import React, {
  type FC,
  memo,
  useEffect,
  useState,
} from "@messenger/lib/teact/teact";

import BusinessAuthHeader from "./BusinessAuthHeader";
import useI18n from "@messenger/hooks/useI18n";
import { callApi } from "@messenger/api/gramjs";
import { Api } from "@messenger/lib/gramjs";

type OwnerForm = Pick<any, "firstName" | "lastName" | "username">;

const SignUpWorker: FC = () => {
  const { dictionary } = useI18n(["business"]);

  const [isValid, setIsValid] = useState(true);
  const [error, setError] = useState(false);

  const [form, setForm] = useState<OwnerForm>({
    firstName: "",
    lastName: "",
    username: "",
  });

  const [invite, setInvite] = useState<Api.auth.TypeSignUpInvite | undefined>(
    undefined,
  );
  const checkInvite = async () => {
    const params = new URLSearchParams(window.location.search);
    const companyInvite = params.get("companyInvite")?.replace(" ", "");
    if (companyInvite) {
      const result = await callApi("checkCompanyInvite", {
        invite: companyInvite,
      });
      if (!result) {
        setIsValid(false);
      }
      setInvite(result);
    }
  };

  useEffect(() => {
    checkInvite();
  }, []);

  const handleFormChange = (value: string, id: keyof OwnerForm) => {
    setForm({
      ...form,
      [id]: value,
    });
  };

  const handleSubmit = async () => {
    const params = new URLSearchParams(window.location.search);
    const companyInvite = params.get("companyInvite")?.replace(" ", "") || "";
    const result = await callApi("signUpWorker", {
      username: form.username,
      firstName: form.firstName,
      lastName: form.lastName,
      invite: companyInvite,
    });
    if (!result) {
      setError(true);
    }
    console.log("result", result);
  };

  return (
    <div className="flex flex-col items-center w-full h-full">
      <BusinessAuthHeader
        title={
          isValid
            ? `${(invite?.company as Api.Company)?.title || ""} ${dictionary.business.signUpWorker.companyInvite}`
            : dictionary.business.errors.invalidHash
        }
      />
      {isValid ? (
        <div className="flex flex-col gap-2 w-full max-w-sm h-full">
          <input
            className="h-11 text-black rounded-xl w-full border border-gray-300 px-3 focus:outline-none focus:border-[#37CB37]"
            placeholder={dictionary.business.signIn.userName}
            value={form["username"]}
            onChange={(e) => handleFormChange(e.target.value, "username")}
          />
          <input
            className="h-11 text-black rounded-xl w-full border border-gray-300 px-3 focus:outline-none focus:border-[#37CB37]"
            placeholder={dictionary.business.signIn.firstName}
            value={form["firstName"]}
            onChange={(e) => handleFormChange(e.target.value, "firstName")}
          />
          <input
            className="h-11 text-black rounded-xl w-full border border-gray-300 px-3 focus:outline-none focus:border-[#37CB37]"
            placeholder={dictionary.business.signIn.lastName}
            value={form["lastName"]}
            onChange={(e) => handleFormChange(e.target.value, "lastName")}
          />
          {!isValid && (
            <span className="text-red-500 text-left text-xs pl-4">
              {dictionary.business.errors.invalidHash}
            </span>
          )}
          {error && (
            <span className="text-red-500 text-left text-xs pl-4">
              {dictionary.business.errors.unexpected}
            </span>
          )}
          <button
            onClick={handleSubmit}
            className="mt-auto h-11 w-full max-w-sm bg-black rounded-xl text-[#37CB37] cursor-pointer"
          >
            {dictionary.business.signIn.start}
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default memo(SignUpWorker);
